import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../components/layout';
import { getObject } from '../services/storage'
import { S3ENV } from '../components/envar'
import { isBrowser } from '../services/auth'
// import { Container } from 'reactstrap'

export default class tests extends Component {
    constructor(props) {
        super(props);

        let objContent =  S3ENV.TEST + S3ENV.FILE_EXT
        let envPrefix = S3ENV.ENV
        const objUri = envPrefix + '-' + objContent;

        this.state = {
            objItems: false,
        };

        if(isBrowser()) this.renderObject(objUri)
    }

    renderObject = async (objVar) => {

        this.setState({
            objItems : await getObject(objVar),
        })

    }

    render() {
        return (
            <Layout>
                <div dangerouslySetInnerHTML={ {__html: this.state.objItems.post_content} } />
            </Layout>
        )
    }
}
